import { isAndroid, isIOS } from 'react-device-detect';
import {useHistory} from "react-router-dom";

const StoreButtons = (jobId) => {

    let history = useHistory();

    function redirect(url){
        history.goBack()
        setTimeout(function(){
            window.location.href = url;
        }, 5);
    }

    function renderButton(){
        if(isIOS || isAndroid){

            if(isIOS){
                return  <div className="containerAppStore">
                    {/*<a*/}
                    {/*    href="https://apps.apple.com/us/app/fittly/id1570186375"*/}
                    {/*    className="btnAppStore"*/}
                    {/*    target="_blank"*/}
                    {/*    rel="noreferrer"*/}
                    {/*    */}
                    {/*>*/}
                        <button className="cleanBtn" onClick={() => redirect("https://apps.apple.com/us/app/fittly/id1570186375")}>
                        <img
                            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&releaseDate=1628294400&h=d508b9c9c159f7d2eede6f0bb45722b6"
                            alt="Download on the App Store"
                            className="imgAppStore"
                        />
                        </button>
                    {/*</a>*/}
                </div>
            }else{
                return <div className="containerPlayStore">
                    {/*<a href="https://play.google.com/store/apps/details?id=com.fittly.app" target="_blank" rel="noreferrer">*/}
                    <button className="cleanBtn" onClick={() => redirect("https://play.google.com/store/apps/details?id=com.fittly.app")}>
                        <img
                            alt="Get it on Google Play"
                            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                            className="btnPlayStore"
                        />
                    </button>
                    {/*</a>*/}
                </div>
            }


        }
        return <>
            <div className="containerAppStore">
                {/*<a*/}
                {/*    href="https://apps.apple.com/us/app/fittly/id1570186375"*/}
                {/*    className="btnAppStore"*/}
                {/*    target="_blank"*/}
                {/*    rel="noreferrer"*/}
                {/*>*/}
                <button className="cleanBtn" onClick={() => redirect("https://apps.apple.com/us/app/fittly/id1570186375")}>
                    <img
                        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&releaseDate=1628294400&h=d508b9c9c159f7d2eede6f0bb45722b6"
                        alt="Download on the App Store"
                        className="imgAppStore"
                    />
                </button>
                {/*</a>*/}
            </div>
            <div className="containerPlayStore">
                <button className="cleanBtn" onClick={() => redirect("https://play.google.com/store/apps/details?id=com.fittly.app")}>
                {/*<a href="https://play.google.com/store/apps/details?id=com.fittly.app" target="_blank" rel="noreferrer">*/}
                    <img
                        alt="Get it on Google Play"
                        src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                        className="btnPlayStore"
                    />
                </button>
                {/*</a>*/}
            </div>
        </>
    }

  return (
      <div className="btnContainer d-flex">
          {renderButton()}
    </div>
  );
};

export default StoreButtons;
