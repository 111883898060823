import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "../components/Header";
import Intro from "../components/Intro";
import JobPost from "../components/Main/JobPost";
import Main from "../components/Main";
import Login from "../components/Login";

import { UnlockFeatures } from "../components/UnlockFeatures/UnlockFeatures";

export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Header hideButton={true} />
          <Intro />
          <Main />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/job/:id">
          <JobPost />
        </Route>
        <Route path="/unlockfeatures">
          <UnlockFeatures />
        </Route>
      </Switch>
    </Router>
  );
}
