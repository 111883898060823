import { Row, Col, Nav } from "react-bootstrap";

import InfoSpecs from "./InfoSpecs";
import { useContext } from "react";
import { AuthUserContext } from "../../../navigation/AuthUserProvider";
import { Link } from "react-router-dom";
import Share from "../../Header/Share";

const Card = ({ info }) => {
  //console.log(info)
  const { profile } = useContext(AuthUserContext);

  return (
    <div className="resultCard">
      <div className="wrapper">
        <div className="headerJobCard w-100 d-flex justify-content-start">
          <Link to={`/job/${info.id}`}>
            <div className="logo">
              <img src={info.provider?.media} alt={info.provider?.name} className="img-fluid" />
            </div>
          </Link>
          <div className="w-100 d-flex justify-space-around ">
            <div className="info">
              <Link to={`/job/${info.id}`}>
                <h4 className="gymName">{info.provider.name}</h4>
                <p className="gymLocation">
                  {info?.provider?.address?.city && info?.provider?.address?.city},{" "}
                  {info?.provider?.address?.country && info?.provider?.address?.country}
                </p>
              </Link>
            </div>

            <Nav className={`navShare`}>
              <Share job={info} isCard={true} />
            </Nav>
          </div>
        </div>
        <Link to={`/job/${info.id}`}>
          <div className="content">
            <h5>{info.title ? info.title : "No title available"}</h5>
            <p>{info.role_desc ? info.role_desc : "No description available"}</p>
          </div>
          <div className="specs">
            <Row className="gx-2">
              <Col md={4} xs={4}>
                {info.years_working !== "" && <InfoSpecs title="experience" info={info.years_working} />}
              </Col>

              <Col md={4} xs={4}>
                {/*{info.salary_max > 0 && <InfoSpecs title="salary" info={info.salary_max} disabled={!profile && true} />}*/}
                <InfoSpecs title="salary" info={info.salary_max} disabled={!profile && true} />
              </Col>

              <Col md={4} xs={4}>
                <InfoSpecs title="schedule" subinfo={info.schedule} disabled={!profile && true} />
              </Col>
            </Row>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Card;
