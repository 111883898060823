import React from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";

import StoreButtons from "../Intro/StoreButton/StoreButtons";
import deviceDesktop from "../../../src/assets/unlock_features/device_desktop.png";
import deviceMobile from "../../../src/assets/unlock_features/device_mobile.png";
import checkGreen from "../../../src/assets/unlock_features/check_green.png";
import { Link } from "react-router-dom";
import logo from "../../assets/logo_header.png";
import connect from "../../assets/connect.png";
import envelope from "../../assets/envelope.png";
import wsp from "../../assets/wsp.png";

export const UnlockFeatures = () => {
  return (
    <div className="unlock">
      <Container fluid className={`head`}>
        <Container>
          <Row className="d-flex justify-content-between d-block pt-3">
            <Col md={3} xs={3} className="pt-2">
              <Link to="/">
                <img src={logo} alt="Fittly" />
              </Link>
            </Col>
            <Col md={9} xs={9} className="d-flex justify-content-end text-start pt-2">
              <Nav className={`justify-content-center`}>
                <Nav.Item>
                  <Nav.Link>
                    <img src={connect} alt="Fittly" className="iconLink" />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link>
                    <img src={envelope} alt="Fittly" className="iconLink" />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link>
                    <img src={wsp} alt="Fittly" className="iconLink" />
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>

          <Row className="d-flex justify-content-between d-block pt-5">
            <Col md={12}>
              <Link className="back" to="/">
                Back
              </Link>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container className="containWrapper">
        <Container className={`contain`}>
          <Row className="pt-sm-0 pt-md-0 pt-lg-3">
            <Col lg={6} md={12} className="align-content-start justify-content-start">
              <div className={"devicePicture"}>
                <img src={deviceDesktop} alt="" />
              </div>
              <div className={"deviceMobile"}>
                <img src={deviceMobile} alt="" />
                <StoreButtons />
              </div>
            </Col>
            <Col lg={6} md={12} sm={12} className="containInc">
              <h1>Unlock all features with the fittly app!</h1>
              <ul className={"checklist"}>
                <li>
                  <img src={checkGreen} alt="Fittly Jobs" /> find the best jobs for you
                </li>
                <li>
                  <img src={checkGreen} alt="Fittly Jobs" />
                  filter by salary range and schedule
                </li>
                <li>
                  <img src={checkGreen} alt="Fittly Jobs" />
                  create a profile and apply to as many jobs as you want
                </li>
                <li>
                  <img src={checkGreen} alt="Fittly Jobs" /> get discovered by top fitness employers
                </li>
              </ul>
              <div className="align-content-start align-items-start align-items-sm-center mt-5 linkBox">
                <div className="btnDonwloadApp d-sm-flex d-block justify-content-center">
                  <StoreButtons />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};
