import axios from "axios";

export const getSkills = () => {
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_BASE_URL}/category/list`,
    })
      .then(function (response) {
        resolve(response.data.categories);
      })
      .catch(function (response) {
        reject(response);
      });
  });
};

export const getJobs = (filter, actualPage, jobsPerPage) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_BASE_URL}/trainer/jobs/find?limit=${jobsPerPage ? jobsPerPage : 10000}${
        actualPage ? `&page=${actualPage}` : ""
      }${filter ? filter : ""}`,
    })
      .then(function (response) {
        resolve(response.data.body);
      })
      .catch(function (response) {
        reject(response);
      });
  });
};
export const getJob = (id) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_BASE_URL}/trainer/jobs/${id}`,
    })
      .then(function (response) {
        resolve(response.data.job);
      })
      .catch(function (response) {
        reject(response);
      });
  });
};
