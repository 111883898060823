import { Container, Row, Col } from "react-bootstrap";
import introBg from "../../assets/intro.png";
import StoreButtons from "./StoreButton/StoreButtons";

function Intro() {
  return (
    <>
      <Container fluid className="intro">
        <Container fluid="xxl">
          <Row className="d-sm-flex justify-content-between d-block pt-3">
            <Col md={7} className="text-start flex-column d-flex justify-content-center">
              <h1>
                Find the best jobs
                <br className="d-lg-block d-none" /> in fitness
              </h1>
              <h2>
                Jobs available for fitness professionals nationwide, download our app today
                <br className="d-sm-block d-none" /> to browse jobs or post to our job board if you are a gym owner!
              </h2>
            </Col>

            <Col md={5} className="d-none d-md-block">
              <div className=" h-100">
                <div className="storeButtonsIntro d-sm-flex d-block align-content-end align-items-end justify-content-center">
                  <img src={introBg} alt="Fittly" className="img-fluid introBg" />
                  <div className="btnDonwloadApp d-sm-flex d-block justify-content-center">
                    <StoreButtons />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="storeButtonsIntro d-md-none d-block">
        <Container className="d-block d-md-none">
          <Col md={5} xs={7} className="pt-5">
            <div className=" h-100">
              <div className="btnDonwloadApp d-block">
                <StoreButtons />
              </div>
            </div>
          </Col>
        </Container>
      </Container>

      <Container>
        <Row>
          <Col md={12} className="text-center py-4 pb-sm-5">
            <h3 className="titIntroH3">Jobs for you</h3>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Intro;
