import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Card from "../Card";
import loading from "../../../assets/loading.svg";
import PublicitarySpace from "../PublicitarySpace";
import loadingWhite from "../../../assets/loadingWhite.svg";
import InfiniteScroll from "react-infinite-scroll-component";

const Results = ({ jobsPaginate, notFound, filters, handleMoreJobs, hasMore }) => {
  //AGREGO FOOTER BG
  useEffect(() => {
    document.body.classList.remove("noBodyBG");
  }, []);

  const hasFilters = (filters) => {
    const params = new URLSearchParams(filters);
    let text = "";
    if (params.get("q")) text = params.get("q");

    if (text.length > 0) {
      text = text.replaceAll("*", "");
    }

    return {
      hasSearch: text.length > 0 ? true : false,
      text,
    };
  };

  const tableDetail = jobsPaginate.map((job, index) => {
    let val;

    if (index === 9) {
      val = <PublicitarySpace />;
    } else {
      val = (
        <Col xl={4} lg={6} md={6} sm={6} key={index}>
          <Card info={job} />
        </Col>
      );
    }

    return <>{val}</>;
  });

  if ((!jobsPaginate && !notFound) || jobsPaginate.length === 0) {
    return (
      <Container fluid className="results">
        <Container>
          <Row>
            <Col md={12} className="text-center pt-5 mt-3" style={{ minHeight: "600px" }}>
              <img src={loading} alt="Loading..." />
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }

  if (notFound) {
    return (
      <Container fluid className="results">
        <Container>
          <Row>
            <Col md={12} className="text-center pt-5 mt-3 pb-5" style={{ minHeight: "600px" }}>
              {" "}
              {/* <h2>Ops...</h2> */}
              <h4>We have some problems, please try in few minutes</h4>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }

  if ((jobsPaginate.length <= 0 || notFound) && hasFilters(filters).hasSearch) {
    return (
      <Container fluid className="results">
        <Container>
          <Row>
            <Col md={12} className="text-center pt-5 mt-3  pb-5" style={{ minHeight: "600px" }}>
              <h3>no job results with value "{hasFilters(filters).text}"</h3>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }

  if ((jobsPaginate.length <= 0 || notFound) && !hasFilters(filters).hasSearch) {
    return (
      <Container fluid className="results">
        <Container>
          <Row>
            <Col md={12} className="text-center pt-5 mt-3  pb-5" style={{ minHeight: "600px" }}>
              {" "}
              <h3>no job results for this filters</h3>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }

  return (
    <Container fluid className="results">
      <Container fluid="xxl">
        <Row className="pt-4 g-3 pb-5">
          {/* {jobs.map((job) => (
            <Col lg={4} md={6} sm={6} key={job.id}>
              <Card info={job} />
            </Col>
          ))} */}

          <InfiniteScroll
            initialScrollY={2000}
            dataLength={jobsPaginate.length}
            //next={handleMoreJobs}
            next={handleMoreJobs}
            //inverse={true}
            // onScroll={() => {
            //   handleMoreJobs();
            // }}
            hasMore={hasMore}
            //hasChildren={true}
            //pullDownToRefreshThreshold={150}
            //scrollThreshold={0.4}
            loader={
              <Container>
                <Row>
                  <Col md={12} className="text-center pt-0 mt-0" style={{ minHeight: "436px" }}>
                    <img src={loadingWhite} alt="Loading..." />
                  </Col>
                </Row>
              </Container>
            }
            endMessage={
              <p style={{ textAlign: "center", color: "white" }}>
                <b>You have seen all jobs</b>
              </p>
            }
          >
            <Row className="pt-4 g-3 pb-5">{tableDetail}</Row>
          </InfiniteScroll>
        </Row>
      </Container>
    </Container>
  );
};

export default Results;
