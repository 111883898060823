import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

const JobHeader = ({ jobInfo }) => {
  function buildAddress() {
    let strCity = "";
    if (jobInfo?.provider?.address?.city.length > 0) {
      strCity = jobInfo?.provider?.address?.city;
    }
    let strCountry = "";
    if (jobInfo?.provider?.address?.country.length > 0) {
      strCountry = jobInfo?.provider?.address?.country;
    }
    let strAddress = "";
    if (strCity.length >= 1) {
      if (strCountry.length >= 1) {
        strAddress = `${strCity}, ${strCountry}`;
      } else {
        strAddress = strCountry;
      }
    } else {
      if (strCountry.length >= 1) {
        strAddress = `${strCountry}`;
      } else {
        strAddress = "";
      }
    }
    return strAddress;
  }

  return (
    <div className="JobHeader">
      <Container fluid>
        <Container>
          <Row>
            <Col md={12} className="headerJobPost">
              <Row>
                <Col>
                  <Link className="linkBackToResults" to="/">
                    Back to Job Board
                  </Link>
                </Col>
              </Row>

              <Row>
                <Col md={6} className="mx-auto text-center">
                  <div className="logoJobPost">
                    <img src={jobInfo.provider.media} alt={jobInfo.provider.name} className="img-fluid" />
                  </div>
                  <div className="infoGymJobSpec d-flex justify-content-center flex-column">
                    <h3>{jobInfo.provider.name}</h3>
                    <h1>{jobInfo.title}</h1>
                    {buildAddress().length > 0 ? (
                      <div className="mx-auto">
                        <p className="location">{buildAddress()}</p>
                      </div>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default JobHeader;
