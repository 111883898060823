import { Row, Col, Button } from "react-bootstrap";
import logo from "../../../assets/fittly_big.png";
import x from "../../../assets/x.png";
import proTravel from "../../../assets/fitness_pro_travel_vertical.png";

const PublicitarySpace = () => {
  return (
    <Row style={{ marginLeft: "0px" }}>
      <Col md={12} style={{ minHeight: "400px", backgroundColor: "white", borderRadius: "20px" }} className="mt-5 mb-5">
        <Row>
          <Col
            md={12}
            className="d-flex justify-content-center align-content-center align-items-center mt-5 mb-4 mx-auto flex-md-row flex-column"
          >
            <img src={logo} alt="Fittly" className="img-fluid mb-4" />

            <div className="p-4 d-md-block d-none">
              <img src={x} alt="x" className=" img-fluid" />
            </div>

            <img src={proTravel} alt="Fitness Pro Travel" className="img-fluid mb-4" />
          </Col>
        </Row>

        <Row>
          <Col md={8} xs={10} className="mx-auto text-center pb-5">
            <p className="mb-4">
              Looking for teaching vacation opportunities? Fit Bodies, Inc. Teaching Vacations provides unique
              opportunities to share fitness with resort guests from all over the world, while instructors enjoy
              all-inclusive resort vacations in luxury destinations. Resorts raise the level of quality and professional
              level of services offered to discerning guests at exceptional resorts worldwide through the service of Fit
              Bodies, Inc. Teaching Vacations.
            </p>

            <a
              href="https://fitnessprotravel.com/affiliate/47360"
              target="_blank"
              rel="noreferrer"
              className="btn btn-blue mt-2"
            >
              Create your free account today
            </a>

            <div className="pt-md-5 pb-md-2 pb-0 pt-3">
              <iframe
                title="Teaching Vacation Experience"
                src="https://player.vimeo.com/video/447178711?h=b36f5ff963"
                width="100%"
                height="360"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PublicitarySpace;
