import { useEffect, useState } from "react";
import { Container, Row, Col, Form, FormControl } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { getSkills } from "../../services/api/commonApiService";
import Geosuggest from "react-geosuggest";
import searchSettings from "../../assets/searchSettings.png";
import { Animated } from "react-animated-css";
import { useHistory, useLocation, Link } from "react-router-dom";
const animatedComponents = makeAnimated();

const Filter = ({ handleFilter, queryFilterURLParam, latURLParam, lonURLParam, categoriesURLParam, locationLabel }) => {
  let history = useHistory();
  let location = useLocation();

  const [skills, setSkills] = useState([]);
  const [skillsForSelect, setSkillsForSelect] = useState([]);

  /* STATES FOR MENU */
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [showFilters, setShowFilters] = useState(false);
  const [showContainerFilters, setShowContainerFilters] = useState(false);

  /* STATES FOR FILTER */
  const [filter, setFilter] = useState([]);
  const [filterUrl, setFilterUrl] = useState([]);
  const [skillsFilter, setSkillsFilter] = useState([]);
  const [locationFilter, setLocationFilter] = useState(null);
  const [queryFilter, setQueryFilter] = useState("");

  useEffect(() => {
    let locationParam = {
      label: "",
      location: {
        lat: "",
        lng: "",
      },
    };

    if (latURLParam && lonURLParam && latURLParam.length > 0 && lonURLParam.length > 0) {
      locationParam = {
        label: locationLabel ? locationLabel : "",
        location: {
          lat: latURLParam,
          lng: lonURLParam,
        },
      };
      setLocationFilter(locationParam);
    }
    setQueryFilter(queryFilterURLParam ?? "");

    let categoryParam = [];
    if (categoriesURLParam && categoriesURLParam.length > 0) {
      categoryParam = categoriesURLParam.split(",");

      if (categoryParam.length > 0) {
        let categoryObj = [];

        // eslint-disable-next-line
        categoryParam.map(function (elem) {
          let tmpObj = elem.split("@");
          if (tmpObj.length === 2) {
            categoryObj.push({
              value: tmpObj[0],
              label: tmpObj[1],
            });
            return elem;
          }
        });
        setSkillsFilter(categoryObj);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryFilterURLParam, latURLParam, lonURLParam, categoriesURLParam]);

  /* GET SCREEN RESOLUTION */
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  /*LISTEN TO SCREEN RESOLUTION FOR SHOW FILTER TO PREVENT HIDE */
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (windowDimensions.width > 767) {
      setShowContainerFilters(true);
      setShowFilters(true);
    }
  }, [windowDimensions]);

  /* useEffect for filter  */
  useEffect(() => {
    // if (
    //   (locationFilter &&
    //     locationFilter.location &&
    //     locationFilter.location.lat.length > 0 &&
    //     locationFilter.location.lng.length > 0) ||
    //   (skillsFilter && skillsFilter.length > 0) ||
    //   (queryFilter && queryFilter.length > 0)
    // ) {
    //SETEO FILTRO PARA API URL
    setFilter(
      `${
        skillsFilter.length > 0
          ? skillsFilter
              .map(function (elem) {
                return `&categories[]=` + elem.value;
              })
              .join("")
          : ""
      }${locationFilter ? `&geo=1&lat=${locationFilter.location.lat}` : ""}${
        locationFilter ? `&lon=${locationFilter.location.lng}` : ""
      }${queryFilter ? `&q=*${queryFilter.split(" ").join(" AND ")}*` : ""}`
    );

    //SETEO FILTRO PARA QUERY SEARCH PARAMS
    setFilterUrl(
      `${
        skillsFilter.length > 0
          ? `&categories=${skillsFilter
              .map(function (elem) {
                return `${elem.value}@${elem.label}`;
              })
              .join(",")}`
          : ""
      }${locationFilter ? `&geo=1&lat=${locationFilter.location.lat}` : ""}${
        locationFilter ? `&lon=${locationFilter.location.lng}` : ""
      }${locationFilter ? `&lbl=${locationFilter.label}` : ""}${queryFilter ? `&q=${queryFilter}` : ""}
    `.substring(1)
    );
  }, [skillsFilter, locationFilter, queryFilter]);

  useEffect(() => {
    if (filter.length > 0) {
      handleFilter(filter);

      history.push({
        pathname: location.pathname,
        search: `${filterUrl}`,
      });
    } else {
      history.push({
        pathname: location.pathname,
        search: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  /* Traigo SKILLS */
  useEffect(() => {
    getSkills().then((res) => {
      setSkills(res);
    });
  }, []);

  /* Seteo los skills en custom select */
  useEffect(() => {
    const options = skills.map((elem) => ({
      value: elem.id,
      label: elem.name,
    }));
    setSkillsForSelect(options);
  }, [skills]);

  /* HANDLERS */
  function handleSkills(e) {
    setSkillsFilter(e);
  }
  function handleLocation(e) {
    setLocationFilter(e);
  }

  function handleQuery(e) {
    if (e.target.value.length <= 50) {
      setQueryFilter(e.target.value);
    }
  }
  function handleShowFilter(e) {
    setShowFilters(!showFilters);

    if (showFilters) {
      setTimeout(() => {
        setShowContainerFilters(false);
      }, 300);
    } else {
      setTimeout(() => {
        setShowContainerFilters(true);
      }, 0);
    }
  }

  return (
    <Container fluid>
      <Container fluid="xxl">
        <Row className="gx-2">
          <Col
            xxl={8}
            xl={8}
            lg={12}
            md={12}
            sm={12}
            className={`order-md-0 order-1 ${showContainerFilters ? "d-block pt-3" : "d-none"}`}
          >
            <Animated
              animationIn="fadeIn"
              animationOut="fadeOut"
              animationInDuration={500}
              animationOutDuration={500}
              isVisible={showFilters}
              className="filters"
            >
              <Row>
                <Col md={12}>
                  <Row className="gx-2">
                    {skills.length > 0 && (
                      <Col xxl={3} xl={3} lg={6} md={12} sm={12} className="pb-2 pb-xl-0">
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          options={skillsForSelect}
                          placeholder="Skills"
                          classNamePrefix="inputFilter"
                          onChange={handleSkills}
                          value={skillsFilter}
                        />
                      </Col>
                    )}
                    <Col xxl={3} xl={3} lg={6} md={12} sm={12} className="pb-2 pb-xl-0">
                      <Geosuggest
                        onSuggestSelect={handleLocation}
                        initialValue={locationLabel}
                        value={locationFilter}
                      />
                    </Col>
                    <Col xxl={3} xl={3} lg={6} md={6} sm={12} className="pb-2 pb-md-0">
                      <Link to="/unlockfeatures">
                        <Form.Control
                          size="md"
                          type="text"
                          placeholder="Salary"
                          className="inputFilter disabled"
                          onClick={() => history.push("/unlockfeatures")}
                          disabled
                        />
                      </Link>
                    </Col>
                    <Col xxl={3} xl={3} lg={6} md={6} sm={12} className="pb-2 pb-md-0">
                      <Link to="/unlockfeatures">
                        <Form.Control
                          size="md"
                          type="text"
                          placeholder="Hours per week"
                          className="inputFilter disabled"
                          onClick={() => history.push("/unlockfeatures")}
                          disabled
                        />
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Animated>
          </Col>
          <Col xxl={4} xl={4} lg={12} md={12} sm={12} className="order-md-1 order-0 pt-3">
            <Row>
              <Col md={12} className="d-md-flex justify-content-end d-block">
                <div className="d-xl-block d-flex w-100">
                  <FormControl
                    placeholder="search by job title or company"
                    aria-label="searchQuery"
                    aria-describedby="searchQuery"
                    className="inputFilter withIcon"
                    onInput={handleQuery}
                    value={queryFilter}
                  />
                  <img
                    src={searchSettings}
                    alt="Open Filters"
                    className="d-block d-md-none img-fluid"
                    onClick={() => handleShowFilter()}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Filter;
