import { Container, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import JobHeader from "./JobHeader";
import JobSpecsBar from "./JobSpecsBar";
import Contact from "../../Header/Contact";
import Header from "../../Header";
import JobsSkillsCard from "./JobSkillsCard";
import { getJob } from "../../../services/api/commonApiService";
import loadingIMG from "../../../assets/loading.svg";

const JobPost = () => {
  let history = useHistory();

  const [loading, setLoading] = useState(true);
  const [jobSelected, setJobSelected] = useState(null);

  let { id } = useParams();

  //REMUEVO FOOTER BG
  useEffect(() => {
    document.body.classList.add("noBodyBG");
  });

  //TRAIGO JOBS
  useEffect(() => {
    getJob(id)
      .then((res) => {
        setLoading(false);
        setJobSelected(res);
      })
      .catch(() => {
        setLoading(false);
        setJobSelected(null);
      });
  }, [id]);

  // //FILTRO EL JOB SELECCIONADO
  // useEffect(() => {
  //   if (!loadingAllJobs) {
  //     const resultado = jobs.find((job) => job.id === Number(id));

  //     setJobSelected(resultado);
  //   }
  // }, [loadingAllJobs]);

  if (loading) {
    return (
      <>
        <Container fluid className="vh-100">
          <Container className="vh-100">
            <Row className="vh-100">
              <Col
                md={12}
                className="text-center 100-vh d-flex justify-content-center align-content-center align-items-center"
              >
                <img src={loadingIMG} alt="Loading..." />
              </Col>
            </Row>
          </Container>
        </Container>
      </>
    );
  }

  if (!jobSelected) {
    return (
      <>
        <Container fluid className="vh-100">
          <Container className="vh-100">
            <Row className="vh-100">
              <Col
                md={12}
                className="text-center 100-vh d-flex justify-content-center align-content-center align-items-center flex-column"
              >
                <h2>Job not found</h2>
                <button
                  className="btn-brown little mt-3"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Return
                </button>
              </Col>
            </Row>
          </Container>
        </Container>
      </>
    );
  }

  return (
    <>
      <Header jobPostPage={true} hideMobile={true} />
      <JobHeader jobInfo={jobSelected} />
      <JobSpecsBar jobInfo={jobSelected} />
      <Container>
        <Row>
          <Col md={6} className="mx-auto pt-5 bodyJobPost">
            {jobSelected.provider.about_us && (
              <>
                <h2>About us</h2>
                <div className="boxInfoJob">
                  <p className="description">{jobSelected.provider.about_us}</p>
                </div>
              </>
            )}

            {jobSelected.role_desc && (
              <>
                <h2>About the role</h2>
                <div className="boxInfoJob">
                  <p className="description">{jobSelected.role_desc}</p>
                </div>
              </>
            )}

            {jobSelected.activities.length > 0 && (
              <>
                <h2>Skill Required</h2>
                <Row>
                  <Col md={12} className="d-flex justify-content-start  flex-wrap">
                    {jobSelected.activities.map((activitie) => (
                      <JobsSkillsCard title={activitie.name} img={activitie.image} key={activitie.id} />
                    ))}
                  </Col>
                </Row>
              </>
            )}

            {jobSelected.benefits_desc && (
              <>
                <h2>Benefits</h2>
                <div className="boxInfoJob">
                  <p className="description">{jobSelected.benefits_desc}</p>
                </div>
              </>
            )}

            <Row>
              <Col md={12} className="text-center pt-5 pb-5">
                <div className="text-center pt-3">
                  <Contact hideButton={false} hideLoginFooter={true} job={jobSelected} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default JobPost;
