const JobsSkillsCard = ({ title, img }) => {
  return (
    <div className="skillCard">
      <div className="imgSkill">
        <img src={img} alt="Skill Name" />
      </div>
      <div className="infoSkill">{title}</div>
    </div>
  );
};

export default JobsSkillsCard;
