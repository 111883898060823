import { findByValue } from "../../../../utils/helpers";
import { Link, useHistory } from "react-router-dom";

const JobSpecs = ({ title, info, subinfo, disabled }) => {
  // function handleUnlock() {
  //   history.push("/login");
  // }

  //FOR SCHEDULE
  function translateSchedule(schedule) {
    if (schedule === "PART_TIME") {
      return "Part Time";
    }
    if (schedule === "FULL_TIME") {
      return "Full Time";
    }
    if (schedule === "") {
      return "N/A";
    }
    return schedule;
  }

  //FOR YEARSWORKING
  const yearsWorking = [
    {
      label: "< 1",
      value: "0.9",
    },
    {
      label: "1-2",
      value: "1.5",
    },
    {
      label: "3-4",
      value: "3.5",
    },
    {
      label: "5-9",
      value: "5.5",
    },
    {
      label: "10+",
      value: "10.1",
    },
  ];

  function translateYearsWorking(ywk) {
    try {
      let dat = findByValue(yearsWorking, "value", ywk.toString());
      if (dat) {
        return dat.label;
      }
      return yearsWorking[0].label;
    } catch (e) {
      if (yearsWorking[0]) {
        return yearsWorking[0].label;
      }
    }
    return "";
  }

  return (
    <div
      className={`specsCard d-flex  align-content-center align-items-center justify-content-center flex-column  ${
        disabled && "disabled"
      }`}
    >
      <div className="w-100 d-flex justify-content-end">
        {disabled && (
          // <button className="unlockBtn" onClick={handleUnlock}>
          <Link to={`/unlockfeatures`}>
            <button className="unlockBtn">unlock</button>
          </Link>
        )}
      </div>

      <div className="boxInfo">
        {!disabled && (
          <>
            <p className="info">
              {title === "experience" && translateYearsWorking(info)}
              {title === "salary" && info}
            </p>
            <p className="subInfo">
              {title === "schedule" && translateSchedule(subinfo)}
              {title === "experience" && "years"}
              {title === "salary" && "usd/hs"}
            </p>
          </>
        )}
      </div>
      <p className={`titleInfo ${title}`}>{title}</p>
    </div>
  );
};

export default JobSpecs;
