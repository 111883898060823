import { Nav } from "react-bootstrap";
import connect from "../../../assets/connect.png";
import envelope from "../../../assets/envelope.png";
import wsp from "../../../assets/wsp.png";
import connectWhite from "../../../assets/connectWhite.png";
import envelopeWhite from "../../../assets/envelopeWhite.png";
import wspWhite from "../../../assets/wspWhite.png";

import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const Share = ({ job, jobPostPage, isCard }) => {
  function buildURL(id = null) {
    let url = window.location;
    if (id) {
      return `View it at: ${url}/job/${id}`;
    }
    return url;
  }

  return (
    <div className="d-flex justify-content-center mb-3">
      <Nav.Item>
        <div className={""}>
          {job ? (
            <CopyToClipboard
              text={!isCard ? buildURL(job.id) : `${window.location.origin}/job/${job.id}`}
              onCopy={() => {
                // alert("The link was copied to your clipboard");
                MySwal.fire({
                  text: "The link was copied to your clipboard",
                  icon: "success",
                  confirmButtonColor: "#eba14a",
                  confirmButtonText: "Continue",
                });
              }}
            >
              <img src={jobPostPage ? connectWhite : connect} alt="Fittly" className="iconLink" />
            </CopyToClipboard>
          ) : (
            <CopyToClipboard
              text={buildURL()}
              onCopy={() => {
                //alert("The link was copied to your clipboard");
                MySwal.fire({
                  text: "The link was copied to your clipboard",
                  icon: "success",
                  confirmButtonColor: "#eba14a",
                  confirmButtonText: "Continue",
                });
              }}
            >
              <img src={jobPostPage ? connectWhite : connect} alt="Fittly" className="iconLink" />
            </CopyToClipboard>
          )}
        </div>
      </Nav.Item>
      <Nav.Item>
        <div className={""}>
          {job ? (
            <a
              href={`mailto:?body=Join Fittly and Apply to ${job.title} position from ${job.provider.name} - ${
                !isCard ? buildURL(job.id) : `${window.location.origin}/job/${job.id}`
              }&subject=This Role is perfect for you`}
            >
              <img src={jobPostPage ? envelopeWhite : envelope} alt="Fittly" className="iconLink" />
            </a>
          ) : (
            <a
              href={`mailto:?body=Join Fittly and Find the best jobs in fitness - ${buildURL()}&subject=Level up your fitness career with Fittly`}
            >
              <img src={jobPostPage ? envelopeWhite : envelope} alt="Fittly" className="iconLink" />
            </a>
          )}
        </div>
      </Nav.Item>
      <Nav.Item>
        <div className={""}>
          {job ? (
            <a
              href={`https://wa.me/?text=Join Fittly and Apply to ${job.title} position from ${job.provider.name} - ${
                !isCard ? encodeURIComponent(buildURL(job.id)) : `${window.location.origin}/job/${job.id}`
              }`}
            >
              <img src={jobPostPage ? wspWhite : wsp} alt="Fittly" className="iconLink" />
            </a>
          ) : (
            <a
              href={`https://wa.me/?text=Join Fittly and Find the best jobs in fitness - ${encodeURIComponent(
                buildURL()
              )}`}
            >
              <img src={jobPostPage ? wspWhite : wsp} alt="Fittly" className="iconLink" />
            </a>
          )}
        </div>
      </Nav.Item>
    </div>
  );
};

export default Share;
