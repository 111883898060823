import { Nav } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import Share from "../Share";

const Contact = ({ jobPostPage, hideButton, hideLoginFooter, job = null }) => {
  let history = useHistory();

  return (
    <Nav className={`justify-content-center headerBlock`}>
      <Share job={job} jobPostPage={jobPostPage} />
      {!hideButton && (
        <Nav.Item>
          <Nav.Link className="pt-2">
            <button className="btn-brown little" onClick={() => history.push("/unlockfeatures")}>
              Apply for this job
            </button>
          </Nav.Link>
        </Nav.Item>
      )}
    </Nav>
  );
};

export default Contact;
