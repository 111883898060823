import { useEffect, useState } from "react";
import Filter from "../Filter";
import Results from "./Results";

import { getJobs } from "../../services/api/commonApiService";

const urlParams = new URLSearchParams(window.location.search);
const queryFilterURLParam = urlParams.get("q") || null;
const latURLParam = urlParams.get("lat") || null;
const lonURLParam = urlParams.get("lon") || null;
const categoriesURLParam = urlParams.get("categories") || null;
const locationLabel = urlParams.get("lbl") || null;

const Main = () => {
  let jobsPerPage = 6;
  const [filterStatus, setFilterStatus] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [actualPage, setActualPage] = useState(1);
  const [actualPageForHandle, setActualPageForHandle] = useState(2);
  const [jobsPaginate, setJobsPaginate] = useState([]);

  const [notFound, setNotFound] = useState(false);

  //FILTRO LOS JOBS CUANDO CAMBIA EL FILTRO

  //TRAIGO JOBS
  useEffect(() => {
    if (filterStatus || (!queryFilterURLParam && !latURLParam && !lonURLParam && !categoriesURLParam)) {
      getJobs(filterStatus, actualPage, jobsPerPage)
        .then((res) => {
          setJobsPaginate(res.data);
          setActualPage(1);
          setActualPageForHandle(2);

          if (actualPage * jobsPerPage < res.total.value) {
            setHasMore(true);
          }
        })
        .catch(() => {
          setNotFound(true);
          setActualPage(1);
          setActualPageForHandle(2);
          setHasMore(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatus]);

  //MANAGE FILTER
  const handleFilter = (e) => {
    setFilterStatus(e);
  };

  //HANDLE INFINITE SCROLL
  const handleMoreJobs = () => {
    let newPage = actualPageForHandle + 1;

    getJobs(filterStatus, actualPageForHandle, jobsPerPage)
      .then((res) => {
        setJobsPaginate([...jobsPaginate, ...res.data]);

        setActualPageForHandle(newPage);

        //DEFINIR HAS MORE COMO FALSE (PAGINA ACTUAL * CANTIDAD DE ITEMS > TOTAL DE ITEMS)
        if (actualPageForHandle * jobsPerPage > res.total.value) {
          setHasMore(false);
        }
      })
      .catch((error) => {
        setNotFound(true);
      });
  };

  return (
    <>
      <Filter
        handleFilter={handleFilter}
        queryFilterURLParam={queryFilterURLParam}
        latURLParam={latURLParam}
        lonURLParam={lonURLParam}
        categoriesURLParam={categoriesURLParam}
        locationLabel={locationLabel}
      />
      <Results
        jobsPaginate={jobsPaginate}
        jobsPerPage={jobsPerPage}
        handleMoreJobs={handleMoreJobs}
        actualPage={actualPageForHandle}
        notFound={notFound}
        filters={filterStatus}
        hasMore={hasMore}
      />
    </>
  );
};

export default Main;
