import "./styles/styles.scss";
import { AuthUserProvider } from "./navigation/AuthUserProvider";
import Routes from "./navigation/Routes";

function App() {
  return (
    <AuthUserProvider>
      <Routes />
    </AuthUserProvider>
  );
}

export default App;
