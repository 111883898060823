import { Container, Row, Col } from "react-bootstrap";
import JobSpecs from "../JobSpecs";
import { useContext } from "react";
import { AuthUserContext } from "../../../../navigation/AuthUserProvider";

const JobSpecsBar = ({ jobInfo }) => {
  const { profile } = useContext(AuthUserContext);

  return (
    <Container>
      <Row>
        <Col xxl={5} xl={7} lg={8} md={10} xs={11} className="mx-auto jobSpecPostTop">
          <Row>
            <Col md={4} xs={4}>
              <JobSpecs title="experience" info={jobInfo.years_working} subinfo="years" />
            </Col>
            <Col md={4} xs={4}>
              <JobSpecs title="salary" info={jobInfo.salary_max} subinfo="usd/h" disabled={!profile && true}/>
            </Col>
            <Col md={4} xs={4}>
              <JobSpecs title="schedule" subinfo={jobInfo.schedule} disabled={!profile && true} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default JobSpecsBar;
