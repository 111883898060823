import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import logo from "../../assets/logo_header.png";
import Contact from "./Contact";

function Header({ jobPostPage, hideButton, hideMobile }) {
  return (
    <Container fluid className={`header ${hideMobile && "d-none d-md-block"}`}>
      <Container fluid="xxl">
        <Row className="d-flex justify-content-between d-block pt-3">
          <Col md={3} xs={3} className="pt-2">
            <Link to="/">
              <img src={logo} alt="Fittly" />
            </Link>
          </Col>

          <Col md={9} xs={9} className="d-flex justify-content-end text-start pt-2">
            <Contact jobPostPage={jobPostPage} hideButton={hideButton} />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Header;
