import { findByValue } from "../../../../utils/helpers";

const InfoSpecs = ({ title, info, subinfo, disabled }) => {
  //FOR SCHEDULE
  function translateSchedule(schedule) {
    if (schedule === "PART_TIME") {
      return "Part Time";
    }
    if (schedule === "FULL_TIME") {
      return "Full Time";
    }
    if (schedule === "") {
      return "N/A";
    }
    return schedule;
  }

  //FOR YEARSWORKING
  const yearsWorking = [
    {
      label: "< 1",
      value: "0.9",
    },
    {
      label: "1-2",
      value: "1.5",
    },
    {
      label: "3-4",
      value: "3.5",
    },
    {
      label: "5-9",
      value: "5.5",
    },
    {
      label: "10+",
      value: "10.1",
    },
  ];

  function translateYearsWorking(ywk) {
    try {
      let dat = findByValue(yearsWorking, "value", ywk.toString());
      if (dat) {
        return dat.label;
      }
      return yearsWorking[0].label;
    } catch (e) {
      if (yearsWorking[0]) {
        return yearsWorking[0].label;
      }
    }
    return "";
  }

  return (
    <div
      className={`specsCard d-flex justify-content-lg-between flex-lg-row align-content-center align-items-center justify-content-center flex-column ${
        disabled && "disabled"
      }`}
    >
      <p className={`titleInfo ${title}`}>{title}</p>
      <div className="boxInfo">
        {!disabled && (
          <>
            <p className="info">
              {title === "experience" && translateYearsWorking(info)}
              {title === "salary" && info}
            </p>
            <p className="subInfo">
              {title === "schedule" && translateSchedule(subinfo)}
              {title === "experience" && "years"}
              {title === "salary" && "usd/hs"}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default InfoSpecs;
