import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { login } from "../../services/api/authApiService";
import axios from "axios";
import { AuthUserContext } from "../../navigation/AuthUserProvider";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import loadingIMG from "../../assets/loading.svg";
import logo from "../../assets/logo_header.png";
import StoreButtons from "../Intro/StoreButton/StoreButtons";

const MySwal = withReactContent(Swal);

const Login = () => {
  let history = useHistory();

  const { setProfile } = useContext(AuthUserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  function emailIsValid() {
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      return true;
    }
    return false;
  }

  const onSubmit = () => {
    if (!loading) {
      if (!emailIsValid(email)) {
        //alert('A Valid Email address is required')

        MySwal.fire({
          text: "A Valid Email address is required",
          icon: "error",
          confirmButtonColor: "#eba14a",
          confirmButtonText: "Back",
        });
        return;
      }
      if (password.length <= 0) {
        MySwal.fire({
          text: "The Password is required",
          icon: "error",
          confirmButtonColor: "#eba14a",
          confirmButtonText: "Back",
        });
        return;
      }
      setLoading(true);
      login(email, password)
        .then((res) => {
          localStorage.setItem("token", res.tokens.access_token);
          axios.defaults.headers.common["Authorization"] = `Bearer ${res.tokens.access_token}`;
          setProfile(res);
          history.goBack();
        })
        .catch((e) => {
          //console.log(e);
          MySwal.fire({
            text: "The email or password is not correct",
            icon: "error",
            confirmButtonColor: "#eba14a",
            confirmButtonText: "Back",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  function handleBack() {
    history.goBack();
  }

  return (
    <div className="vh-100 d-flex justify-content-center align-items-center align-content-center">
      <Container>
        <Row>
          <Col lg={4} md={6} sm={7} className="mx-auto pt-5  text-center">
            <img src={logo} alt="Fittly" />
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={6} sm={7} className="mx-auto pt-5 pb-5">
            <div className="formLogin text-center">
              <h4 className="pb-3">log in to your account</h4>

              <Form.Group className="mb-3 grouped-input pt-4">
                <Form.Label>email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Type"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      if (emailIsValid(event.target.value)) {
                        document.getElementById("password").focus();
                      } else {
                        MySwal.fire({
                          text: "A Valid Email address is required",
                          icon: "error",
                          confirmButtonColor: "#eba14a",
                          confirmButtonText: "Back",
                        });
                      }
                    }
                  }}
                />
                {/*<Form.Text className="text-muted">*/}
                {/*    We'll never share your email with anyone else.*/}
                {/*</Form.Text>*/}
              </Form.Group>
              <Form.Group className="mb-3 grouped-input">
                <Form.Label>password</Form.Label>
                <Form.Control
                  id={"password"}
                  type="password"
                  placeholder="Type"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      onSubmit();
                    }
                  }}
                />
              </Form.Group>
              <Row>
                <Col className="w-100 d-flex justify-content-center pt-4 pb-4">
                  {!loading ? (
                    <Button type="submit" className="btn btn-brown mx-auto w-100" onClick={onSubmit}>
                      Log In
                    </Button>
                  ) : (
                    <img src={loadingIMG} alt="Loading..." />
                  )}
                </Col>
                {/*<Form.Text className="text-muted text-center">*/}
                {/*    Don’t have an account yet? Register in our app*/}
                {/*</Form.Text>*/}
                {/*<a href={'#'} className="text-muted text-center">*/}
                {/*    Register in our app*/}
                {/*</a>*/}
              </Row>
              <Row>
                <Col className="w-100 d-flex justify-content-center pt-4 ">
                  <button type="button" onClick={handleBack} className="btn btn-login little">
                    Return
                  </button>
                </Col>
              </Row>
              <Row>
                <Col className="w-100 d-flex justify-content-center pt-5 pb-3">
                  <h5>
                    Create your free profile today, <br /> join our growing community.
                  </h5>
                </Col>
              </Row>
              <Row>
                <Col className="w-100 d-flex justify-content-center pb-4">
                  <StoreButtons />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
